import React, { useEffect, useState } from 'react'
import './Navigation.scss'
import logo from '../../assets/rainlab_logo.png'
import close_icon from '../../assets/ph_x-bold.svg'
import hamburger_icon from '../../assets/hamburger.svg'
import Collapse from '../Collapse/Collapse'
import { Link } from 'react-router-dom'
import { useData } from '../../context/programsContext'

function Navigation() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isServicesOpen, setServicesOpen] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    
    useEffect(() => { 
        localStorage.getItem('language', language)

        window.addEventListener('scroll', handleScroll); 
        return () => { 
            window.removeEventListener('scroll', handleScroll); 
        }; 
    }, [language]);

    const services = [
        {
            title: "Neurowellness",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Content Neurofeedback",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Research",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Software and Web Development",
            href: "https://neuro-research.ai/neurowellness/en"
        },
        {
            title: "Events",
            href: "https://neuro-research.ai/events/en"
        }
    ];

    const programs = useData().data;

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    }

    const toggleServices = () => {
        setServicesOpen(!isServicesOpen);
    }

    const handleScroll = () => { 
        const isTop = window.scrollY === 0;
        setIsAtTop(isTop);
    }; 

    const handleLangChange = (e) => {
        const select = document.querySelector('#google_translate_element select');
        const lang = e.target.value;
        console.log(lang, localStorage.getItem('language'))
        if (select) {
            setLanguage(lang);
            localStorage.setItem('language', lang);
            if (lang === 'en') {
                // Reset to the original language (English)
                const iframe = document.getElementById(':1.container');
                if (iframe) {
                    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    const originalLangButton = iframeDocument.getElementById(':1.restore');
                    if (originalLangButton) {
                        originalLangButton.click();
                    }
                }
            } else {
                // Translate to the selected language
                select.value = lang;
                select.dispatchEvent(new Event('change'));
            }
        }
    }


  return (
    <header id='navigation' className={(isAtTop ? "" : "collapsed")}>
        <div className='rainbow'>
            <div className='content'>
                <nav className='programs'>
                    {
                        programs.map(p => 
                            p.isDisabled || p.id === "your-program" ? <></> 
                            : (
                                <Link className='program' id={p.id} to={`products/${p.id}`}><span className='title full'>{p.card.title}</span><span className='title short'>{p.id}</span></Link>
                            )
                        )
                    }
                    {/* <Link to={`/products/pp`} className='program' id='peakPerformance'>Peak Performance</Link> */}
                    {/* <Link to={`/products`} className='program' id='stopSmoking'>Stop Smoking</Link> */}
                    {/* <Link to={`/products/wl`} className='program' id='weightLoss'>Weight Loss</Link>
                    <Link to={`/products/st`} className='program' id='stressfulTalks'>Strressful Talks</Link> */}
                </nav>
                <div id="google_translate_element"></div>
                <select id='language-toggle' className='skiptranslate' value={language} onChange={handleLangChange}>
                    <option value={"en"}>EN</option>
                    <option value={"fr"}>FR</option>
                    <option value={"uk"}>UA</option>
                </select>
            </div>
        </div>
        <div id='menu' className={(isAtTop ? "" : "collapsed")}>
            <button className={"menu_item tab " + (isServicesOpen ? "" : "collapsed")} onClick={toggleServices}>Services</button>
            <Link className='menu_item' to={'/products'}>Products</Link>
            <a className='menu_item' id='logo' href="https://neuro-research.ai/quantum/en"><img alt=""  src={logo}/></a>
            <a className='menu_item' href='https://neuro-research.ai/quantum/en#team'>About Us</a>
            <a className='menu_item' href='https://neuro-research.ai/contact-us/en'>Contacts</a>
            <button onClick={toggleMobileMenu}><img alt=""  src={hamburger_icon}/></button>
        </div>
        <div className={'submenu ' + (isServicesOpen ? "" : "hidden ") + (isAtTop ? "" : "collapsed")}>
            {services.map((item, index) => (
                    <a className='menu_item' href={item.href} key={index}>{item.title}</a>
                ))}
        </div>
        <div id='menuMobile' className={isMobileMenuOpen ? "" : "hidden"} onClick={(e) => e.preventDefault()}>
            <div className='content'>
                <nav>
                    <Collapse head="Services" list={services}/>
                    <Link className='menu_item' to={'/products'}>Products</Link>
                    <a className='menu_item' href='https://neuro-research.ai/quantum/en#team'>About</a>
                    <a className='menu_item' href='https://neuro-research.ai/contact-us/en'>Contacts</a>
                </nav>
                <button onClick={toggleMobileMenu}><img alt=""  src={close_icon}/></button>
            </div>
        </div>
    </header>
  )
}

export default Navigation