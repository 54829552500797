import React, { createContext, useContext, useState } from 'react';

import pp_icon from '../assets/programs/program_icons/pp.png';
import ss_icon from '../assets/programs/program_icons/ss.png';
import wl_icon from '../assets/programs/program_icons/wl.png';
import st_icon from '../assets/programs/program_icons/st.png';
import yp_icon from '../assets/programs/program_icons/yp.png';
import pp_avatar from '../assets/programs/wa_avatars/supermind_avatar_pp.jpg'
import ss_avatar from '../assets/programs/wa_avatars/supermind_avatar_ss.jpg'
import wl_avatar from '../assets/programs/wa_avatars/supermind_avatar_wl.jpg'
import st_avatar from '../assets/programs/wa_avatars/supermind_avatar_st.jpg'
import yp_avatar from '../assets/programs/wa_avatars/supermind_avatar_yp.jpg'

import ss_preview_msg_image from '../assets/ss_preview_msg_image.jpeg'
import wl_preview_msg_image from '../assets/wl_preview_msg_image.jpeg'

const ProgramsContext = createContext();

export const ProgramsProvider = ({ children }) => {
  const [data, setData] = useState([
    {
        id: "peak-performance",
        preview: {
          title: "SuperMind: Peak Performance",
          avatar: pp_avatar,
          messages: [
            {
              role: "ai",
              text: "To enhance your sleep, Maria, establish a calming bedtime routine. Try turning off screens an hour before bed, dimming the lights, and maybe doing some light stretching or meditation. You can also try listening to soothing music or nature sounds. Consistency is key -go to bed and wake up at the same time each day. How about trying one of these tonight?🌙😴✨"
            },
            {
              role: "user",
              text: "Ok, Neil. I will dream about going to the moon with you tonight✨💕"
            },
            {
              role: "ai",
              text: "That sounds out of this world, Maria!🌕🚀 Sweet dreams, and enjoy your moon adventure. I'm here whenever you need support. Sleep well!😊✨"
            }
          ]
        },
        info: {
          title: "SuperMind: Peak Performance Maximize Your Potential with 24/7 AI Guidance",
          subtitle: "Working hard to achieve your dream or overcome challenges?",
          infoHTML: (
            <>
              <p>Our AI coaching program helps you to become more focused, energized, and to increase your achievements through:</p><br/>
              <ul>
                <li>Expert-designed, evidence-based daily tips guiding you step-by-step;</li>
                <li>Confidential dialogues, accessible  24/7 through WhatsApp;</li>
                <li>Engaging, human-like conversations with a character modeled after Neil Armstrong, the first person on the Moon.</li>
              </ul><br/>
              <b>Inspired by NASA’s astronaut training, our program combines the most scientifically validated techniques for productivity. Every day, you’ll receive an impactful lesson in a message with a practical task — guiding you to success.</b>
            </>
          )
        },
        card: {
          title: "Peak Performance",
          image_url: pp_icon
        },
        isDisabled: true
      },
      {
        id: "stop-smoking",
        preview: {
          title: "SuperMind: Stop Smoking",
          avatar: ss_avatar,
          messages: [
            {
              role: "ai",
              text: "Need help with anything related to your smoke-free journey, Maria? 😊"
            },
            {
              role: "user",
              text: "Which one is better for quitting smoking?",
              image: ss_preview_msg_image
            },
            {
              role: "ai",
              text: "Choosing snacks that make you happy can be a nice reward for staying smoke-free, but remember moderation is key! The Cranberry Bliss Bar has fewer calories, so it might be a lighter option. Ultimately, go with what satisfies you the most without overindulging. How are you celebrating your progress today, Maria?😊"
            }
          ]
        },
        info: {
          title: "SuperMind: Stop Smoking Fast and For Life with 24/7 AI Support",
          subtitle: "Quit smoking with our research-backed AI coaching program:",
          infoHTML: (
            <>
              <ul>
                <li>Expert-designed daily AI messages with tailored, evidence-based tips guiding you step-by-step;</li>
                <li>Confidential dialogues, accessible  24/7 through WhatsApp, anywhere;</li>
                <li>Engaging and entertaining, with a friendly, human-like character inspired by an FBI hostage negotiator.</li>
              </ul><br/>
              <b>Our program combines the most scientifically validated knowledge and techniques for quitting. Each day, you will receive an impactful message with a practical task — guiding you step-by-step to the result and a healthy, smoke-free life.</b>
            </>
          )
        },
        card: {
          title: "Stop Smoking",
          image_url: ss_icon
        },
        isDisabled: false
      },
      {
        id: 'healthy-diet',
        preview: {
          title: "SuperMind: Healthy Diet",
          avatar: wl_avatar,
          messages: [
            {
              role: "user",
              text: " Thor! My ideal weight today! My goal is achieved, thank you!",
              image: wl_preview_msg_image
            },
            {
              role: "ai",
              text: "By the mighty hammer of Asgard, fair mortal Sujata, thou hast reached thy goal! 🎉 Let this moment be recorded in the annals of thy journey. What next heroic deeds shall we pursue together? 🌟⚡️"
            }
          ]
        },
        info: {
          title: "SuperMind: Healthy Diet Crush Cravings and Form Habits with 24/7 AI Help",
          subtitle: "Tired of diets that don’t last and losing motivation?",
          infoHTML: (
            <>
              <p>Our AI coaching program is designed to overcome the most common dietary challenges and it includes:</p><br/>
              <ul>
                <li>Daily, expert-designed messages with step-by-step guide;</li>
                <li>24/7 confidential support on WhatsApp, keeping you on track through cravings and setbacks;</li>
                <li>Motivating, human-like conversations with a character inspired by resilient and legendary Bruce Lee.</li>
              </ul><br/>
              <b>We combine scientifically proven techniques to help you build lasting habits. Every day, you’ll receive an insightful message and practical task — transforming your body into ideal shape.</b>
            </>
          )
        },
        card: {
          title: "Healthy Diet",
          image_url: wl_icon
        },
        isDisabled: false
      },
      {
        id: 'stressful-talks',
        preview: {
          title: "SuperMind: Stressful Talks",
          avatar: st_avatar,
          messages: [
            {
              role: "user",
              text: "I feel like my compassion and other actions towards solving the problem are perceived as a weakness"
            },
            {
              role: "ai",
              text: (
                <>
                <>Maria, it is natural to feel this way, but remember that true strength lies in your kindness and resolve. Consider these thoughts:</>
                <ol>
                  <li><b>Redefine Strength:</b> View compassion as a powerful tool rather than a vulnerability. Your ability to stay calm and empathetic amidst challenges is a testament to your resilience.</li>
                  <li><b>Be Assertive:</b> Balance your compassion with assertiveness. Clearly communicate your boundaries and expectations.</li>
                  <li><b>Clarify Intentions:</b> Let them know your intentions are to strengthen the relationship, acknowledging any misunderstandings.</li>
                  <li><b>Lead with Integrity:</b> Continue acting in alignment with your values, regardless of others’ perceptions.</li>
                </ol>
                <>Would you find it helpful to explore techniques for asserting boundaries while maintaining compassion?</>
                </>
              )
            }
          ]
        },
        info: {
          title: "SuperMind: Stressful Talks Be on Top in Hard Conversations with 24/7 AI Help",
          subtitle: "Do you seek mastery in stressful communication?",
          infoHTML: (
            <>
              <p>Our program includes:</p><br/>
              <ul>
                <li>Daily, wisdom-infused messages with a research-based lessons to upgrade your communication skills;</li>
                <li>24/7 confidential support via WhatsApp, guiding you through high-pressure interactions;</li>
                <li>Empowering conversations with a character drawing inspiration from the wisdom of Mahatma Gandhi, transforming stress into strength.</li>
              </ul><br/>
              <b>We integrate evidence-based techniques to foster resilience and strategic communication. Each day, you receive an enlightening message and practical task — empowering you to excel in high-stakes dialogue.</b>
            </>
          )
        },
        card: {
          title: "Stressful Talks",
          image_url: st_icon
        },
        isDisabled: true
      },
      {
        id: 'your-program',
        preview: {
          title: "SuperMind: Your Program",
          avatar: yp_avatar,
          messages: [
            {
              role: "user",
              text: " Thor! My ideal weight today! My goal is achieved, thank you!",
              image: wl_preview_msg_image
            },
            {
              role: "ai",
              text: "By the mighty hammer of Asgard, fair mortal Sujata, thou hast reached thy goal! 🎉 Let this moment be recorded in the annals of thy journey. What next heroic deeds shall we pursue together? 🌟⚡️"
            }
          ]
        },
        info: {
          title: "SuperMind: Your Program Stay Ahead with Custom AI Coaching Service",
          infoHTML: (
            <>
              <p>Unlock the transformative potential of AI for your personal goals or professional services. Let us craft your AI coaching program with:</p><br/>
              <ul>
                <li>Customized Daily Insights, Reminders, and Action Plans;</li>
                <li>Integration of Biometric Data and AI Analysis;</li>
                <li>24/7 AI Support for Real-Time Guidance;</li>
                <li>Selection of Scientifically Validated Methods Only.</li>
              </ul><br/>
              <b>In addition to our tailored AI coaching programs, we offer cutting-edge research services and innovative software development. Our white-label solutions provide customizable tools to meet your specific needs. Unravel your next break through discovery — contact us today at info@neuro-research.ai or book a call!</b><br/><br/>
              <div><a href='https://neuro-research.ai/contact-us/en'  target="_blank" rel="noreferrer noopener" className='btn primary submit'>Contact Us</a></div>
            </>
          )
        },
        card: {
          title: "Your Program",
          image_url: yp_icon
        },
        isDisabled: false
      }
  ]);

  return (
    <ProgramsContext.Provider value={{ data, setData }}>
      {children}
    </ProgramsContext.Provider>
  );
};

export const useData = () => useContext(ProgramsContext);