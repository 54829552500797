import React from 'react';
import './Programs.scss';
import Authorization from '../Authorization/Authorization';
import ProgramOverview from './ProgramOverview/ProgramOverview';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { useData } from '../../context/programsContext';

function Programs() {
  const programs = useData().data;

  const location = useLocation();

  const { product } = useParams();

  let selectedProgram;
  if (product) selectedProgram = programs.find((p) => p.id === product);

  if (product && (selectedProgram === undefined || selectedProgram.isDisabled)){
    return <Navigate to="/404"/>
  }

  return (
    <>
      {
        product && programs.some(item => item.id === product) ? <ProgramOverview program={selectedProgram}/> :
        (<>
          <section className='content'>
            <h1>Join the SuperMind Club — 24/7 AI Research-Backed Coaching with Live Support!</h1>
            <p className='subtitle'>Our AI-coaches, integrated into WhatsApp, will confidentially support you 24/7 with research-backed programs, complemented by online group meetings.<br></br><b>See the results in just 1 month!</b></p>
          </section>
          <section className='content'>
            <div id='programs'>
              {
                programs.map(p => {
                  return p.isDisabled ? (<></>) : (
                    <Link to={`/products/${p.id}`} className='program'>
                      <img alt=""  className='icon' src={p.card.image_url}/>
                      <div className='title'>{p.card.title}</div>
                    </Link>
                  )
                })
              }
            </div>
          </section>
        </>)
      }
      {selectedProgram && selectedProgram.id !== "yp" ? (
        <>
        <section className='rainbow'>
          <div className='content'>
            <h2>Limited time only! Test the trial version with no credit card!</h2>
          </div>
        </section>
        <section className='content'>
          <div id="registration" className='registration'>
            <Authorization key={location.pathname}/>
          </div>
        </section>
        </>
      ) : <></>}
      <section className='content inputs'>
        <h2>More Information? Subscribe or book a call!</h2>
        <p className='subtitle'>Get a free consultation with our experts, or subscribe to stay updated on new programs, discounts, special offers, and insights!</p>
        <a href='https://neuro-research.ai/contact-us/en'  target="_blank" rel="noreferrer noopener" className='btn primary submit'>Contact Us</a>
      </section>
    </>
  )
}

export default Programs